var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-editor" },
    [
      _c("div", { staticClass: "report-edit-header" }, [
        _vm._v("Report Template")
      ]),
      _c("div", { staticClass: "report-editor-toolbar" }, [
        _c(
          "div",
          {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "toolbar-icon",
            attrs: { title: "Save Template" },
            on: { click: _vm.saveTemplate }
          },
          [_c("i", { staticClass: "fa fa-save" })]
        ),
        _c(
          "div",
          {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "toolbar-icon",
            attrs: { title: "Refresh Preview" },
            on: { click: _vm.emitTemplateChange }
          },
          [_c("i", { class: _vm.$config.icons.general.refresh })]
        )
      ]),
      _c("codemirror", {
        ref: "cmEditor",
        attrs: { options: _vm.cmOptions },
        model: {
          value: _vm.currentTemplate,
          callback: function($$v) {
            _vm.currentTemplate = $$v
          },
          expression: "currentTemplate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }