<template>
  <div class="report-edit-view">
    <div>
      <div class="reports-toolbar">
          <div>
            <i class="icon-button"
              :class="$config.icons.general.back"
              v-b-tooltip
              title="Back to Reports"
              @click="$router.back()"
            ></i>
          </div>
        <h2>Report Preview:</h2>
        <div></div>
      </div>
      <ReportPage v-if="reportTemplate" :reportTemplate="reportTemplate"
                  :start-date="reportParams['start_date']"
                  :end-date="reportParams['end_date']"
                  :date-grouping="reportParams['time_grouping']"
      ></ReportPage>
    </div>
    <div v-if="report">
      <div class="green-divider"></div>
      <ReportEditor :report="report" @change="reportTemplateChange"></ReportEditor>
      <ReportProperties :report="report" @params-change="changeProperties" ref="reportProps"></ReportProperties>
    </div>
  </div>
</template>

<script>

import ReportPage from '@/components/stats/ReportPage'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import ReportEditor from '@/components/stats/ReportEditor'
import ReportProperties from '@/components/stats/ReportProperties'
export default {
  name: 'reports-view',
  components: {
    ReportProperties,
    ReportEditor,
    ReportPage
  },
  data: function () {
    return {
      report: null,
      reportTemplate: null,
      reportParams: null
    }
  },
  async mounted () {
    if (this.$route.params.hasOwnProperty('id')) {
      this.reportId = this.$route.params.id
    }
    if (!this.reportId) {
      console.error('No Report ID provided!')
    } else {
      let resp = await DataProvider.getReport(this.reportId)
      if (resp.success) {
        console.log(resp)
        if (resp.data.data === null) {
          resp.data.data = {}
        }
        this.report = resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  methods: {
    changeProperties (newParams) {
      this.reportParams = newParams
    },
    reportTemplateChange (newTemplate) {
      this.reportTemplate = newTemplate
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report-edit-view {
  display: flex;
  flex-direction: column;
  background-color: $default-content-background;
  overflow: auto;
  padding: 1%;
  width: 100%;
}

.reports-heading {
  color: $text-color-heading;
  font-weight: 600;
}

h1 {
  color: white;
}

.reports-toolbar {
  display: flex;
  flex-direction: row;
  font-size: 2em;
  justify-content: space-between;
  align-items: center;
  color: $theme-color-primary-6;
  padding: 5px;
}

.reports-toolbar * {
  margin-left: 10px;
}

</style>
