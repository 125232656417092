var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-edit-view" }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "reports-toolbar" }, [
          _c("div", [
            _c("i", {
              directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
              staticClass: "icon-button",
              class: _vm.$config.icons.general.back,
              attrs: { title: "Back to Reports" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            })
          ]),
          _c("h2", [_vm._v("Report Preview:")]),
          _c("div")
        ]),
        _vm.reportTemplate
          ? _c("ReportPage", {
              attrs: {
                reportTemplate: _vm.reportTemplate,
                "start-date": _vm.reportParams["start_date"],
                "end-date": _vm.reportParams["end_date"],
                "date-grouping": _vm.reportParams["time_grouping"]
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm.report
      ? _c(
          "div",
          [
            _c("div", { staticClass: "green-divider" }),
            _c("ReportEditor", {
              attrs: { report: _vm.report },
              on: { change: _vm.reportTemplateChange }
            }),
            _c("ReportProperties", {
              ref: "reportProps",
              attrs: { report: _vm.report },
              on: { "params-change": _vm.changeProperties }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }