<template>
  <div class="report-editor">
    <div class="report-edit-header">Report Template</div>
    <div class="report-editor-toolbar">
      <div class="toolbar-icon" v-b-tooltip title="Save Template" @click="saveTemplate">
        <i class="fa fa-save"></i>
      </div>
      <div class="toolbar-icon" @click="emitTemplateChange" v-b-tooltip title="Refresh Preview">
        <i :class="$config.icons.general.refresh"></i>
      </div>
    </div>
    <codemirror ref="cmEditor" v-model="currentTemplate" :options="cmOptions" ></codemirror>
  </div>
</template>
<script>

import Vue from 'vue'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
// import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// language
import 'codemirror/mode/htmlmixed/htmlmixed.js'
// theme css
import 'codemirror/theme/monokai.css'
// require active-line.js
// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/search/searchcursor.js'
// hint
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/selection/active-line.js'
// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/match-highlighter.js'
// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'

Vue.component('default', {
  template: '<div>Loading Report</div>'
})

export default {
  name: 'ReportEditor',
  props: {
    report: Object
  },
  data () {
    return {
      currentView: 'default',
      defaultTemplate: '<div><h1>New Report</h1></div><div>',
      currentTemplate: null,
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: false,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        mode: 'text/html',
        // hint.js options
        hintOptions: {
          completeSingle: false
        },
        keyMap: 'sublime',
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: 'monokai',
        extraKeys: {
          'Ctrl': 'autocomplete',
          'Ctrl-S': () => this.saveTemplate()
        }
      }
    }
  },
  mounted () {
    console.log(this.report)
    if (Object.hasOwn(this.report.data, 'template')) {
      this.currentTemplate = this.report.data.template
    } else {
      this.currentTemplate = this.defaultTemplate
    }
  },
  methods: {
    emitTemplateChange () {
      this.$emit('change', this.currentTemplate)
    },
    async saveTemplate () {
      let newData = { ...this.report.data }
      newData.template = this.currentTemplate
      let resp = await DataProvider.editReport(this.report.id, null, null, newData)
      if (resp.success) {
        AlertHelper.successToast('Report Template Saved', 'Report Updated')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report-editor {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: start;
}

.report-editor-toolbar{
  background: $theme-color-background-2;
  color: $theme-color-primary-3;
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: flex-start;
}

.report-editor-toolbar * {
  margin: 0px 3px;
}

.control-back {
  justify-self: flex-start;
}

.report-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}

.report-test-class {
  background: $theme-color-primary-3
}

.report-edit-header {
  color: white;
  border-bottom: 1px solid white;
}

.toolbar-icon {
  color: $theme-color-primary-4;
  font-size: 1.5em;
}

.toolbar-icon:hover {
  color: $theme-color-primary-2;
}



</style>
